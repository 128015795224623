/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

import * as React from 'react';
import {Grid, Drawer, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import SearchDialogContent from 'components/common/SearchDialogContent';
import {TIPS_DRAWER_WIDTH} from 'types/search';

interface InstructionProps {
  close: () => void;
  open: boolean;
}

export default function Instructions({close, open}: InstructionProps) {
  return (
    <Drawer
      sx={{
        height: 'calc(100% - 150px)',
        width: TIPS_DRAWER_WIDTH,
        '& .MuiDrawer-paper': {
          height: 'calc(100% - 150px)',
          flexShrink: 0,
          width: TIPS_DRAWER_WIDTH,
          marginTop: '90px',
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <Grid container sx={{justifyContent: 'space-between'}}>
        <Typography variant="h6" ml={2} fontWeight="bold" display="inline">
          {' '}
          <InfoIcon
            sx={{mt: 1, color: 'text.primary', height: '24px', width: '24px'}}
          />{' '}
          Search Tips
        </Typography>
        <IconButton onClick={() => close()}>
          <CloseIcon
            sx={{height: '24px', width: '24px', mr: 2}}
            aria-label="Clear button"
          />
        </IconButton>
      </Grid>
      <SearchDialogContent />
    </Drawer>
  );
}
