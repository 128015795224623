/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

export type Tags = {
  searchString?: string;
  sort: string;
  sizeFilter: string;
  sourceFilter: string[];
  distroFilter: string[];
  typeFilter: string[];
  keywordFilter: string[];
  startDate: string;
  endDate: string;
  userFilter: string;
  count: string;
  start: string;
  name?: string;
  email?: string;
  affiliation?: string;
};

export type Filters = {
  searchString?: string;
  sizeFilter: string;
  sourceFilter: string[] | string;
  distroFilter: string[] | string;
  typeFilter: string[] | string;
  keywordFilter: string[] | string;
  startDate: string;
  endDate: string;
  userFilter: string;
};

export type Params = Filters & {
  count: string;
  start: string;
  sort: string;
  table?: string;
};

export const TAG_NAME_MAP = {
  sizeFilter: 'Max File Size',
  typeFilter: 'Type',
  sourceFilter: 'Source',
  distroFilter: 'Distribution',
  startDate: 'Uploaded After',
  endDate: 'Uploaded Before',
  keywordFilter: 'Keyword',
};

export type DataElement = {
  type: string;
  value: string;
};

export type CountElement = {
  datatype: string;
  type: string;
  value: string;
};

export type TypeFilterType = {
  filterType: DataElement;
  typeCount: CountElement;
};

export type SourceFilterType = {
  filterSource: DataElement;
  sourceCount: CountElement;
};

export type DistroFilterType = {
  filterDistroLabel: DataElement;
  distroLabelCount: CountElement;
};

export type KeywordFilterType = {
  filterKeyword: DataElement;
  keywordCount: CountElement;
};

export type WordcloudTerm = {
  keyword: DataElement;
  count: CountElement;
};

export const SORT_OPTIONS = [
  {label: 'Most results', value: 'mostResults'},
  {label: 'A-Z', value: 'alphabetical'},
];

export type SortOptions = {
  field: string;
  direction: string;
};

export const MAX_FILTERS = 10;

export type Keyword = {
  keywordCount: {
    datatype: string;
    type: string;
    value: string;
  };
  filterKeyword: {
    type: string;
    value: string;
  };
};

export const TIPS_DRAWER_WIDTH = 410;
