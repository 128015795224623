/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

/* eslint-disable react/jsx-no-constructed-context-values */

'use client';

import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
} from 'react';
import {Filters} from 'types/search';

interface SearchHistoryProps {
  searchHistory: Filters[];
  addSearch: (search: Filters) => void;
}

const SearchHistoryContext = createContext<SearchHistoryProps | undefined>(
  undefined,
);

// eslint-disable-next-line react/function-component-definition
export const SearchHistoryProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [searchHistory, setSearchHistory] = useState<Filters[]>([]);

  const addSearch = (search: Filters) => {
    const duplicateSearch = searchHistory.some(
      (prevSearch) =>
        JSON.stringify(prevSearch.searchString) ===
        JSON.stringify(search.searchString),
    );
    if (!duplicateSearch) setSearchHistory([search, ...searchHistory]);
  };

  return (
    <SearchHistoryContext.Provider value={{searchHistory, addSearch}}>
      {children}
    </SearchHistoryContext.Provider>
  );
};

export const useSearchHistoryContext = () => {
  const context = useContext(SearchHistoryContext);
  if (context === undefined) {
    throw new Error(
      'SearchHistoryContext must be used within a SearchHistoryProvider',
    );
  }
  return context;
};
