/**
 SBIR/STTR DATA RIGHTS

 Funding Agreement No. 47QFLA23F0215
 Award Date: 30 Sept 2023
 SBIR/STTR Protection Period: Date of First Deliverable through 20 years
 SBIR/STTR Awardee: Mile Two LLC
 SBIR/STTR Awardee Address: 601 East 3rd Street Dayton, OH 45402

 This is SBIR/STTR Data (or is Computer Software or a Prototype that embodies or includes SBIR/STTR Data)
 to which the SBIR/STTR Awardee has BIR/STTR Data Rights and to which the Federal Government has
 received SBIR/STTR Technical Data Rights (or SBIR/STTR Computer Software Rights) during the SBIR/STTR 
 Protection Period and rights of use for Government Purposes after the SBIR/STTR Protection Period, as those
 terms are defined in the SBIR/STTR Funding Agreement. Awards issues by the U.S. Department of Energy are
 subject to Unlimited Rights after the SBIR/STTR Protection Period, as that term is defined in the SBIR/STTR
 Funding Agreement. Any reproduction of SBIR/STTR Data or portions of such data marked with this legend must
 also reproduce the markings.
 */

import React from 'react';
import {Grid, Typography} from '@mui/material';

function searchinfocontent() {
  return (
    <Grid
      sx={{
        margin: 1.5,
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto',
        },
      }}
    >
      <Typography id="advsearch-description" sx={{mt: 2}}>
        Search results can be filtered using the Filters and Keywords tables to
        the left of the search results. Using Advanced Search, results can
        become even more precise. Search operations used in combination with
        search terms will narrow the scope of the search to give the desired
        results.
      </Typography>
      <Typography id="phrases-title" sx={{mt: 4, fontWeight: 'bold'}}>
        Search for Phrases
      </Typography>
      <Typography paragraph id="phrases-description" sx={{mt: 0}}>
        Terms in double quotes are treated as a phrase.
        <br />
        <span style={{fontStyle: 'italic'}}>Example: </span>&quot;stress
        relief&quot; ... Yields all results containing the phrase &quot;
        <span style={{fontStyle: 'italic'}}>stress relief.</span> &quot;
      </Typography>
      <Typography id="wildcard-title" sx={{mt: 4, fontWeight: 'bold'}}>
        Wild Card Operations
      </Typography>
      <Typography id="wildcard-description" sx={{mt: 0}}>
        DELVE search supports the wildcard character *. Depending on the amount
        of data available, wildcard operations may take longer than other search
        queries.
        <br />
        <span style={{fontStyle: 'italic'}}>Example: </span>
        &quot;ultimate*&quot;... Yields anything beginning with{' '}
        <span style={{fontStyle: 'italic'}}>ultimate.</span> &quot;
      </Typography>
      <Typography id="andoperations-title" sx={{mt: 4, fontWeight: 'bold'}}>
        AND Operations
      </Typography>
      <Typography id="andoperations-description" sx={{mt: 0}}>
        Match two query expressions.
        <br />
        <span style={{fontStyle: 'italic'}}>Example: </span>Cu AND Ag ... Yields
        all results that contain both the terms Cu and Ag.
      </Typography>
      <Typography id="oroperations-title" sx={{mt: 4, fontWeight: 'bold'}}>
        OR Operations
      </Typography>
      <Typography id="oroperations-description" sx={{mt: 0}}>
        Match either of two queries.
        <br />
        <span style={{fontStyle: 'italic'}}>Example: </span>Bi OR Sn ... Yields
        all results that contain at least one of either of the terms Bi or Sn.
      </Typography>
      <Typography id="groupedqueries-title" sx={{mt: 4, fontWeight: 'bold'}}>
        Grouped Queries
      </Typography>
      <Typography id="groupedqueries-description" sx={{mt: 0}}>
        Parentheses indicate grouping.
        <br />
        <span style={{fontStyle: 'italic'}}>Example: </span>(Ag OR Sb) AND alloy
        ... Yields all results containing at least one of the terms Sb or Ag,
        and also contains the term{' '}
        <span style={{fontStyle: 'italic'}}>alloy.</span>
      </Typography>
    </Grid>
  );
}

export default searchinfocontent;
